$LATO: 'Lato', sans-serif;

$GREY: #CCC;
$BACKGROUND_GREY: lighten(#EEEDED, 2.5%);
$SUBTLE_GREY: #F2F2F2;
$MURKY_GREY: #384148;
$DARK_GREY: #333;

$WHITE: #FFF;
$SUBTLE_WHITE: #F9F9F9;

$BLUE: #6495ED;
$RED: #FF6347;

*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
  transition: all 0.2s ease;
}


.user_avatar {
	width: 65px;
	height: 65px;

	display: inline-block;
	vertical-align: middle;

	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
}

.comment_block {
	width: 75%;
	height: 100%;

	//margin: 0 auto;
	padding: 10px;
	.new_comment {
		width: 100%;
		height: auto;
		padding: 20px 0;
		border-top: 1px solid lighten($GREY, 10%);

		.user_comment {
			list-style-type: none;
		}

		.comment_body {
			display: inline-block;
			vertical-align: middle;
			width: calc(100% - 75px);
			margin-left: 10px;
			padding: 5px 10px;
			font-size: .9rem;
			color: #555;
			background-color: $WHITE;			
			
		}

		.comment_toolbar {
			width: 100%;

			ul {
				list-style-type: none;
				padding-left: 75px;
				font-size: 0;

				li {
					display: inline-block;
					padding: 5px;
					font-size: .7rem;
					color: lighten($GREY, 5%);
					&:hover {
						cursor: pointer; 
					}
				}
			}

			.comment_details {
				display: inline-block;
				vertical-align: middle;
				width: 70%;
				text-align: left;
			}

			.comment_tools {
				display: inline-block;
				vertical-align: middle;
				width: 30%;
				text-align: right;
				li {
					&:hover {
						color: $GREY;
					}
				}
			}

			// replies

		}

		.user {
			&:hover {
				color: $BLUE;
				text-decoration: underline;
				}
			}
	}

}