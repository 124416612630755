.header {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid #868686;
  }
  
  .mainNavbar {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: beige;
  }
  
  .header ul {
    list-style: none;
    display: flex;
    align-items: baseline;
    margin: 0;
    padding: 0;
  }
  
  .header li {
    margin: 0 var(--size-4);
  }
  
  .header a {
    color: var(--color-grey-100);
    font-size: var(--size-3);
  }
  
  .header a:hover,
  .header a:active,
  .header a.active {
    color: var(--color-grey-200);
  }
  
  .footer{
    background-color:#42c5f5;
    margin: 0px auto;
    padding: 10px 0px 10px 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }

  .footer a :hover{
    color: white;
  }
  .logo{
    height: 65px;
    width: 65px;
    margin-right: 0.5rem
  }

  .gl-loader {
    position: relative;
    margin: auto;
    max-width: 80px;
    z-index: 102;
    top: 47%;
  }

  .gl-loader-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    opacity: 0.6;
    z-index: 101;
    width: 100%;
    background-color: grey;
  }

  .gl-error {
    color: #d31038
  }
  .gl-toolbar {
    background-color: #EAF8FC;
  }
  
  @media (min-width: 768px) {
    .header ul {
      gap: 0.5rem;
    }
  
    .header a {
      font-size: var(--size-3);
    }
  }
  
