@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 62.5%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  --color-primary: #29b5e8;
  --color-secondary: #71d3dc;
  --color-tertiary: #003545;

  --parimary-text: #4a4a4a;

  --color-active: #edfafd;

  --color-success-100: #a2f0bc;
  --color-success-500: #12bd4b;

  --color-error-100: #f1acc9;
  --color-error-500: #a10c4a;

  --size-1: 0.25rem;
  --size-2: 0.5rem;
  --size-3: 0.75rem;
  --size-4: 1rem;
  --size-5: 1.25rem;
  --size-6: 1.5rem;
  --size-8: 2rem;
  --size-16: 4rem;
  --size-20: 5rem;
  --size-40: 10rem;

  margin: 0;
  background-color: var(--color-grey-500);
  color: var(--parimary-text);
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p,
span,
div {
  color: var(--primary-text);
  font-weight: 400;
  font-size: 1.2rem;
}

button:focus {
  outline: none;
}

[type="checkbox"]:focus,
[type="radio"]:focus {
  --tw-ring-offset-width: 0px !important;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: none;
}

[type="checkbox"] {
  height: 12px;
  width: 12px;
}

[type="text"],
[type="password"],
[type="number"],
textarea,
select {
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #dadada;
  padding: 4px 20px 4px 4px;
  line-height: 18px;
  min-width: 0px;
  min-width: 0px;
  flex: 1;
  transition: all 100ms ease-out;
}

[type="text"]:focus,
[type="password"]:focus,
[type="number"]:focus,
textarea:focus {
  border-color: #447fd1 !important;
}

[type="text"],
[type="password"],
[type="number"],
textarea {
  font-size: 1.2rem !important;
}

.MuiMenu-list {
  max-height: 275px !important;
  overflow-y: auto !important;
}

/** override font family for material ui components */
.MuiTypography-root,
.MuiButtonBase-root,
.MuiInputBase-root {
  font-family: inherit !important;
}

.MuiListItem-button:hover {
  background-color: var(--color-active) !important;
}

/* Search box UI. */
.MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
}

.MuiOutlinedInput-inputMarginDense,
.MuiOutlinedInput-input {
  padding: 6px 20px 6px 6px !important;
}

.MuiInputBase-input:focus,
.MuiOutlinedInput-input:focus,
.MuiInputBase-inputAdornedEnd:focus,
.MuiOutlinedInput-inputAdornedEnd:focus {
  border-color: transparent !important;
  border: 0px solid transparent !important;
}

.MuiInputBase-root > [type="text"],
.MuiInputBase-root > [type="text"]:focus {
  border: 0px solid #dadada !important;
  --tw-ring-color: transparent !important;
}

.MuiBadge-colorPrimary {
  color: white !important;
  font-size: 11px !important;
  background-color: #d9011b !important;
}

/* Nested Dropdown UI. */

.nested-sidebar > .MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.MuiTypography-body1 {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif !important;
}

.MuiListItem-root {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

/** kendo grid cell class name */
.kendo-cell-number {
  text-align: right !important;
}

.kendo-cell-date {
  text-align: center !important;
}

.kendo-cell-center {
  text-align: center !important;
}

.text-center {
  text-align: center !important;
}

.k-grid {
  height: 100% !important;
  max-height: 800px !important;
}

.k-grid-content tr:not(.k-grouping-row) td:not(.k-group-cell) {
  cursor: pointer !important;
}

.k-grid th {
  white-space: normal !important;
}

.k-header > .k-link,
.k-header > .k-link > * {
  font-weight: 600 !important;
  color: #666666 !important;
  font-size: 1.2rem !important;
}

.k-master-row.k-state-selected .k-grid-content-sticky,
.k-master-row.k-state-selected .k-grid-row-sticky {
  background-color: #daf2f7 !important;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: #daf2f7 !important;
}

.k-button {
  font-size: 1.2rem !important;
}

th.k-header.active > div > div {
  color: #169bd5;
}

/** progress bar width override */
.k-progressbar {
  width: 100% !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.k-progressbar-horizontal {
  height: 6px !important;
}

#grid-wrapper {
  padding-right: 950px;
}

#grid-sidebar-wrapper {
  margin-right: -950px;
  right: 975px;
  width: 950px;
  background: #fff;
  position: absolute;
  /* transition: all 0.4s ease 0s; */
}

#grid-wrapper.active {
  padding-right: 0;
}

#grid-wrapper.active #grid-sidebar-wrapper {
  right: 0;
}

#filter-wrapper {
  padding-left: 350px;
}

#filter-sidebar-wrapper {
  margin-left: -350px;
  left: 350px;
  width: 350px;
  background: #fff;
  position: absolute;
  max-height: calc(100% - 120px);
  overflow-x: inherit;
  /* transition: all 0.4s ease 0s; */
}

#filter-wrapper.active {
  padding-left: 0;
}

#filter-wrapper.active #filter-sidebar-wrapper {
  left: 0;
}

/* nprogress bar and spinner color */
#nprogress .bar {
  background: #29b5e8 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #29b5e8, 0 0 5px #29b5e8 !important;
}

#nprogress .spinner-icon {
  border-top-color: #29b5e8 !important;
  border-left-color: #29b5e8 !important;
}

.k-state-selected {
  background-color: #169bd5 !important;
}

/* material icons resizing */
.material-icons.md-11 {
  font-size: 11px;
}
.material-icons.md-12 {
  font-size: 12px;
}
.material-icons.md-14 {
  font-size: 14px;
}
.material-icons.md-16 {
  font-size: 16px;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-20 {
  font-size: 18px;
}
.material-icons.md-22 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.text-red {
  color: red !important;
}

::-webkit-scrollbar-track {
  background-color: white;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} /* the new scrollbar will have a flat appearance with the set background color */

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #d0d0d0;
} /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: white;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0.2);
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */

::-webkit-scrollbar {
  width: 9px;
  -webkit-logical-height: 9px;
  -webkit-max-logical-height: 9px;
}

.k-window {
  background-color: transparent !important;
  box-shadow: none !important;
}

#nprogress .bar {
  background: #006dff !important;
  height: 0.3rem !important;
}

#nprogress .spinner {
  top: auto !important;
  bottom: 15px !important;
}

#nprogress .spinner-icon {
  width: 40px !important;
  height: 40px !important;
}

.text-white {
  color: white !important;
}

p {
  margin-bottom: 0px !important;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit !important;
}

fieldset {
  top: 0px !important;
}

.border {
  border-width: 1px !important;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

hr {
  width: 1px !important;
  height: auto !important;
  border-left: 1px solid gray !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.hover-white:hover {
  color: white !important;
}

.text-right {
  text-align: right !important;
}

.bg-red-hover:hover {
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.red-text {
  color: red !important;
}
.k-toolbar {
  justify-content: flex-end;
}
.k-pager-numbers .k-link {
  color: rgb(14, 15, 15);
}
.k-pager-numbers .k-link:hover, .k-pager-numbers .k-link.k-state-hover {
  color: #141212;
  background-color: rgba(24, 197, 240, 0.08);
}

.k-pager-numbers .k-link:focus, .k-pager-numbers .k-link.k-state-focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(46, 195, 214, 0.12);
}

.k-pager-numbers .k-link.k-state-selected {
  color: #1f1b1b;
  background-color: rgba(43, 216, 207, 0.2);
}

.k-checkbox:checked{
  background-color: white;
}